<template>
  <div class="row">
    <CLoading :loading="loading" />
    <q-space />
    <DatePicker
      :default-date-range="dateRange"
      :disable="false"
      @update:onChangeDateRange="changeDateRange"
    ></DatePicker>
  </div>

  <div class="q-pa-md row q-gutter-md text-center">
    <div class="col">
      <q-card class="my-card">
        <q-card-section>
          <span class="text-bold">{{ $t('label.active_users_compared_with_last_day') }}</span
          ><br />
          <span class="text-positive" v-if="!isMinusActiveUsers">+</span>
          <span class="text-h4 text-positive" v-if="!isMinusActiveUsers">{{ diffActiveUsers }} </span>
          <span class="text-positive" v-if="!isMinusActiveUsers">人</span>
          <span class="text-negative" v-if="isMinusActiveUsers">-</span>
          <span class="text-h4 text-negative" v-if="isMinusActiveUsers">{{ diffActiveUsers }} </span>
          <span class="text-negative" v-if="isMinusActiveUsers">人</span><br />
          <span
            ><span class="text-bold">{{ $t('label.total_active_users') }}</span>
            {{ numberWithCommas(totalActiveUsers) }}人</span
          ><br />
          <span>
            <span class="text-bold">{{ $t('label.today_new_users') }}:</span>
            <span class="text-positive"> {{ numberWithCommas(totalTodayNewUsers) }}人</span> </span
          ><br />
          <span>
            <span class="text-bold">{{ $t('label.today_block_users') }}:</span>
            <span class="text-negative"> {{ numberWithCommas(totalTodayBlockedUsers) }}人</span>
          </span>
          <span>
            <div class="absolute-bottom text-right q-pr-sm" style="bottom: -2px">
              <q-btn no-caps color="primary" size="sm" :label="$t('details')" @click="onDetails()" />
            </div>
          </span>
        </q-card-section>
      </q-card>
    </div>

    <div class="col">
      <q-card class="my-card">
        <q-card-section>
          <span class="text-bold">{{ $t('label.blocked_rate_compared_with_last_day') }}</span> <br />
          <span class="text-h4 text-negative" v-if="!isMinusBlockedUsers">{{ diffBlockedUsers }}</span>
          <span class="text-negative" v-if="!isMinusBlockedUsers">%</span>
          <span class="text-h4" v-if="isMinusBlockedUsers">{{ diffBlockedUsers }}</span>
          <span v-if="isMinusBlockedUsers">%</span>
          <br />
          <span>
            <span class="text-bold">{{ $t('label.blocked_rate') }}</span> {{ blockedRate }} %</span
          ><br />
          <span>
            <span class="text-bold">{{ $t('label.total_blocks') }}:</span> {{ numberWithCommas(totalBlockedUsers) }} /
            {{ numberWithCommas(totalBlockedUsers + totalActiveUsers) }}人
          </span>
          <br />
          <br />
        </q-card-section>
      </q-card>
    </div>
    <div class="col">
      <UsageLineSelector :usage="totalUsageMessages" :currentPlan="totalCurrentPlan"></UsageLineSelector>
    </div>
  </div>

  <div class="q-pa-md row q-gutter-md">
    <div class="col">
      <q-table
        flat
        dense
        :title="$t('label.initial_messages')"
        v-if="initials"
        :rows="initials"
        row-key="date"
        bordered
        :rows-per-page-options="[10]"
        separator="cell"
        :no-data-label="$t('messages.no_data_available')"
      >
        <template v-slot:header>
          <q-tr>
            <q-th style="width: 150px"></q-th>
            <q-th
              ><span class="text-bold">{{ $t('label.push.delivered') }} </span></q-th
            >
            <q-th>
              <span class="text-bold">{{ $t('label.total_user_of_goal') }}</span>
            </q-th>
            <q-th><span class="text-bold">GTR</span> </q-th>
            <q-th
              ><span class="text-bold">{{ $t('label.total_click') }}</span>
            </q-th>
            <q-th><span class="text-bold">CVR</span> </q-th>
            <q-th><span class="text-bold">CV</span> </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="date" :props="props">{{ props.row.date }} </q-td>
            <q-td key="delivered" :props="props">
              {{ numberWithCommas(props.row.delivered) }}
            </q-td>
            <q-td key="total_user_of_goal" :props="props">
              {{ numberWithCommas(props.row.total_user_of_goal) }}
            </q-td>
            <q-td key="gtr" :props="props"> {{ getGTR(props.row) }}% </q-td>
            <q-td key="total_click" :props="props">
              {{ numberWithCommas(props.row.total_click) }}
            </q-td>
            <q-td key="success_rate" :props="props"> {{ getCVR(props.row) }}% </q-td>
            <q-td key="success_count" :props="props">
              {{ numberWithCommas(props.row.success_count) }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="col">
      <q-table
        flat
        dense
        :title="$t('label.push_messages')"
        v-if="pushs"
        :rows="pushs"
        row-key="date"
        bordered
        :rows-per-page-options="[10]"
        separator="cell"
        :no-data-label="$t('messages.no_data_available')"
      >
        <template v-slot:header>
          <q-tr>
            <q-th style="width: 150px"></q-th>
            <q-th
              ><span class="text-bold">{{ $t('label.push.delivered') }} </span></q-th
            >
            <q-th>
              <span class="text-bold">{{ $t('label.total_user_of_goal') }}</span>
            </q-th>
            <q-th><span class="text-bold">GTR</span> </q-th>
            <q-th
              ><span class="text-bold">{{ $t('label.total_click') }}</span>
            </q-th>
            <q-th><span class="text-bold">CVR</span> </q-th>
            <q-th><span class="text-bold">CV</span> </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="date" :props="props">{{ props.row.date }} </q-td>
            <q-td key="delivered" :props="props">
              {{ numberWithCommas(props.row.delivered) }}
            </q-td>
            <q-td key="total_user_of_goal" :props="props">
              {{ numberWithCommas(props.row.total_user_of_goal) }}
            </q-td>
            <q-td key="gtr" :props="props"> {{ getGTR(props.row) }}% </q-td>
            <q-td key="total_click" :props="props">
              {{ numberWithCommas(props.row.total_click) }}
            </q-td>
            <q-td key="success_rate" :props="props"> {{ getCVR(props.row) }}% </q-td>
            <q-td key="success_count" :props="props">
              {{ numberWithCommas(props.row.success_count) }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRange, IOverviewMessage } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import UsageLineSelector from './selectors/UsageLineSelector.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { last7Days, now } from '@/utils/datetime'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    CLoading,
    UsageLineSelector,
    DatePicker,
  },
  directives: { maska },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class OverView extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  totalActiveUsers = 0
  totalBlockedUsers = 0
  totalTodayNewUsers = 0
  totalTodayBlockedUsers = 0
  totalYesterdayActiveUsers = 0
  totalYesterdayBlockedUsers = 0
  pushs: IOverviewMessage[] = []
  initials: IOverviewMessage[] = []

  totalUsageMessages = 0
  totalCurrentPlan = 0
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  firstLoad = true

  get diffActiveUsers() {
    let diff = this.totalTodayNewUsers - this.totalTodayBlockedUsers
    if (diff < 0) {
      diff = diff * -1
    }
    return diff
  }

  get isMinusActiveUsers() {
    const diff = this.totalTodayNewUsers - this.totalTodayBlockedUsers
    if (diff < 0) {
      return true
    }
    return false
  }

  get diffBlockedUsers() {
    let diff = 0
    const lastDay = this.totalYesterdayActiveUsers + this.totalYesterdayBlockedUsers
    if (lastDay !== 0) {
      diff = this.totalYesterdayBlockedUsers / lastDay
    }

    return this.roundNumber(diff * 100)
  }

  get isMinusBlockedUsers() {
    let diff = 0
    const lastDay = this.totalYesterdayActiveUsers + this.totalYesterdayBlockedUsers
    if (lastDay !== 0) {
      diff = this.totalYesterdayBlockedUsers / lastDay
    }
    if (diff === 0) {
      return true
    }
    return false
  }

  get blockedRate() {
    let rate = 0
    if (this.totalActiveUsers !== 0) {
      rate = (this.totalBlockedUsers / this.totalActiveUsers) * 100
    }

    return this.roundNumber(rate)
  }

  async fetchSelectingApp() {
    this.totalActiveUsers = 0
    this.totalBlockedUsers = 0
    this.totalTodayNewUsers = 0
    this.totalTodayBlockedUsers = 0
    this.totalYesterdayActiveUsers = 0
    this.totalYesterdayBlockedUsers = 0
    this.pushs = []
    this.initials = []

    if (!this.appId) {
      return
    }

    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    if (date_from !== '' && date_to !== '') {
      const resp = await this.$store.dispatch(ACTION_APP.OVERVIEW, {
        app_id: this.appId,
        is_test_mode: false,
        date_from: date_from,
        date_to: date_to,
      })
      if (resp) {
        // eslint-disable-next-line
        this.totalActiveUsers = resp['total_active_users']
        // eslint-disable-next-line
        this.totalBlockedUsers = resp['total_blocked_users']
        // eslint-disable-next-line
        this.totalTodayNewUsers = resp['total_today_new_users']
        // eslint-disable-next-line
        this.totalTodayBlockedUsers = resp['total_today_blocked_users']
        // eslint-disable-next-line
        this.totalYesterdayActiveUsers = resp['total_yesterday_active_users']
        // eslint-disable-next-line
        this.totalYesterdayBlockedUsers = resp['total_yesterday_blocked_users']
        // eslint-disable-next-line
        this.totalUsageMessages = resp['total_usage_messages']
        // eslint-disable-next-line
        this.totalCurrentPlan = resp['total_current_plan']
        // eslint-disable-next-line
        const message_statistic: IOverviewMessage[] = resp['message_statistic']
        if (message_statistic) {
          this.initials = message_statistic.filter((item) => item.message_type === 'initial')
          this.pushs = message_statistic.filter((item) => item.message_type === 'push')
        }
      }
    }

    this.loading = false
  }

  getGTR(row: IOverviewMessage) {
    let gtr = 0
    if (row.delivered && row.total_user_of_goal) {
      gtr = (row.total_user_of_goal / row.delivered) * 100

      return this.roundNumber(gtr)
    }

    return 0
  }

  getCVR(row: IOverviewMessage) {
    let cvr = 0
    if (row.total_click && row.success_count) {
      cvr = (row.success_count / row.total_click) * 100
      return this.roundNumber(cvr)
    }

    return 0
  }

  async changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    await this.fetchSelectingApp()
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    this.dateRange = {
      from: last7Days(),
      to: now(),
    }
    await this.fetchSelectingApp()
    this.firstLoad = false
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  onDetails() {
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }
    this.goto('overview_details', {
      app_id: this.appId,
      from_date: date_from,
      to_date: date_to,
    })
  }
}
</script>

<style lang="scss" scoped>
.my-card {
  width: 100%;
  min-height: 280px;
  line-height: 3rem;
}
</style>
