
import { Formater } from '@/utils/formater'
import { maska } from 'maska'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class UsageLineSelector extends Vue {
  @Prop({ default: 0 })
  usage!: number

  @Prop({ default: 0 })
  currentPlan!: number

  get remaining() {
    return this.currentPlan - this.usage
  }

  get appId() {
    return this.$route.params.app_id
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
