
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRange, IOverviewMessage } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import UsageLineSelector from './selectors/UsageLineSelector.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { last7Days, now } from '@/utils/datetime'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    CLoading,
    UsageLineSelector,
    DatePicker,
  },
  directives: { maska },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class OverView extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  totalActiveUsers = 0
  totalBlockedUsers = 0
  totalTodayNewUsers = 0
  totalTodayBlockedUsers = 0
  totalYesterdayActiveUsers = 0
  totalYesterdayBlockedUsers = 0
  pushs: IOverviewMessage[] = []
  initials: IOverviewMessage[] = []

  totalUsageMessages = 0
  totalCurrentPlan = 0
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  firstLoad = true

  get diffActiveUsers() {
    let diff = this.totalTodayNewUsers - this.totalTodayBlockedUsers
    if (diff < 0) {
      diff = diff * -1
    }
    return diff
  }

  get isMinusActiveUsers() {
    const diff = this.totalTodayNewUsers - this.totalTodayBlockedUsers
    if (diff < 0) {
      return true
    }
    return false
  }

  get diffBlockedUsers() {
    let diff = 0
    const lastDay = this.totalYesterdayActiveUsers + this.totalYesterdayBlockedUsers
    if (lastDay !== 0) {
      diff = this.totalYesterdayBlockedUsers / lastDay
    }

    return this.roundNumber(diff * 100)
  }

  get isMinusBlockedUsers() {
    let diff = 0
    const lastDay = this.totalYesterdayActiveUsers + this.totalYesterdayBlockedUsers
    if (lastDay !== 0) {
      diff = this.totalYesterdayBlockedUsers / lastDay
    }
    if (diff === 0) {
      return true
    }
    return false
  }

  get blockedRate() {
    let rate = 0
    if (this.totalActiveUsers !== 0) {
      rate = (this.totalBlockedUsers / this.totalActiveUsers) * 100
    }

    return this.roundNumber(rate)
  }

  async fetchSelectingApp() {
    this.totalActiveUsers = 0
    this.totalBlockedUsers = 0
    this.totalTodayNewUsers = 0
    this.totalTodayBlockedUsers = 0
    this.totalYesterdayActiveUsers = 0
    this.totalYesterdayBlockedUsers = 0
    this.pushs = []
    this.initials = []

    if (!this.appId) {
      return
    }

    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    if (date_from !== '' && date_to !== '') {
      const resp = await this.$store.dispatch(ACTION_APP.OVERVIEW, {
        app_id: this.appId,
        is_test_mode: false,
        date_from: date_from,
        date_to: date_to,
      })
      if (resp) {
        // eslint-disable-next-line
        this.totalActiveUsers = resp['total_active_users']
        // eslint-disable-next-line
        this.totalBlockedUsers = resp['total_blocked_users']
        // eslint-disable-next-line
        this.totalTodayNewUsers = resp['total_today_new_users']
        // eslint-disable-next-line
        this.totalTodayBlockedUsers = resp['total_today_blocked_users']
        // eslint-disable-next-line
        this.totalYesterdayActiveUsers = resp['total_yesterday_active_users']
        // eslint-disable-next-line
        this.totalYesterdayBlockedUsers = resp['total_yesterday_blocked_users']
        // eslint-disable-next-line
        this.totalUsageMessages = resp['total_usage_messages']
        // eslint-disable-next-line
        this.totalCurrentPlan = resp['total_current_plan']
        // eslint-disable-next-line
        const message_statistic: IOverviewMessage[] = resp['message_statistic']
        if (message_statistic) {
          this.initials = message_statistic.filter((item) => item.message_type === 'initial')
          this.pushs = message_statistic.filter((item) => item.message_type === 'push')
        }
      }
    }

    this.loading = false
  }

  getGTR(row: IOverviewMessage) {
    let gtr = 0
    if (row.delivered && row.total_user_of_goal) {
      gtr = (row.total_user_of_goal / row.delivered) * 100

      return this.roundNumber(gtr)
    }

    return 0
  }

  getCVR(row: IOverviewMessage) {
    let cvr = 0
    if (row.total_click && row.success_count) {
      cvr = (row.success_count / row.total_click) * 100
      return this.roundNumber(cvr)
    }

    return 0
  }

  async changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    await this.fetchSelectingApp()
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    this.dateRange = {
      from: last7Days(),
      to: now(),
    }
    await this.fetchSelectingApp()
    this.firstLoad = false
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  onDetails() {
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }
    this.goto('overview_details', {
      app_id: this.appId,
      from_date: date_from,
      to_date: date_to,
    })
  }
}
