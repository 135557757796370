<template>
  <q-card class="my-card">
    <q-card-section>
      <span class="text-bold">{{ $t('label.remaining') }}</span> <br />
      <span class="text-h4 text-positive">{{ numberWithCommas(remaining) }}</span>
      <br />
      <q-linear-progress :value="remaining/currentPlan" color="positive" class="q-mt-sm" />
      <span><span class="text-bold">{{ $t('label.usage') }}:</span> <span class="text-negative">{{ numberWithCommas(usage) }}</span></span
      ><br />
      <span><span class="text-bold">{{ $t('label.limit_on_current_plan') }}:</span> {{ numberWithCommas(currentPlan) }}</span>
      <br />
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { Formater } from '@/utils/formater'
import { maska } from 'maska'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class UsageLineSelector extends Vue {
  @Prop({ default: 0 })
  usage!: number

  @Prop({ default: 0 })
  currentPlan!: number

  get remaining() {
    return this.currentPlan - this.usage
  }

  get appId() {
    return this.$route.params.app_id
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
</script>
